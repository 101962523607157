import React from "react"
import { graphql } from "gatsby"
import {
  Helmet,
  Banner,
  Layout,
  Theme,
  Link,
  ReadMoreLink,
  IconGrid,
  IconGroceries,
  IconSpeechAlt,
  IconShower,
  IconClinical,
  IconCleaning,
  IconMaintenance,
  IconGarden,
  IconRespiteAlt,
  PromoPanelRightImage,
  Variables,
  Section,
  Container,
  Row,
  Box,
  Heading,
  Panel,
  routesObject
} from "@life-without-barriers/react-components"
import { IGatsbyImageData } from "gatsby-plugin-image"
import { Site } from "@life-without-barriers/gatsby-common"
import { contentfulHelpers as Contentful } from "@life-without-barriers/utilities"

import ContactUsSection from "../../../components/services/aged-care/ContactUsSection"
import { AgedCareForm } from "@life-without-barriers/shared-contact-form"

const { agedCareThemeFull } = Theme
const { greyXXXLight } = Variables

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
    ourStaffImage: IGatsbyImageData
    pageResources: {
      edges: [
        {
          node: {
            pageDocuments: Contentful.RemoteFileAsset[]
          }
        }
      ]
    }
  }
}

interface LocationListProps {
  state: string
  locations: string[]
  note?: string
}

const LocationList = ({ state, locations, note }: LocationListProps) => (
  <>
    <div className="fw7 mb3">{state}</div>
    {note && <div className="i pv1 mb2">{note}</div>}
    <ul className="list pa0 ma0 mb4 lh-copy">
      {locations.map((location) => (
        <li key={`${state}-${location}`}>{location}</li>
      ))}
    </ul>
  </>
)

const iconSelector = (type: string, color: string, height?: string) => {
  if (type === "groceries") {
    return <IconGroceries color={color} height={height} />
  }
  if (type === "speechalt") {
    return <IconSpeechAlt color={color} height={height} />
  }
  if (type === "shower") {
    return <IconShower color={color} height={height} />
  }
  if (type === "clinical") {
    return <IconClinical color={color} height={height} />
  }
  if (type === "cleaning") {
    return <IconCleaning color={color} height={height} />
  }
  if (type === "maintenance") {
    return <IconMaintenance color={color} height={height} />
  }
  if (type === "garden") {
    return <IconGarden color={color} height={height} />
  }
  if (type === "respitealt") {
    return <IconRespiteAlt color={color} height={height} />
  }
  return undefined
}

const PageForm = <AgedCareForm formId="desktop-aged-care-contact-form" />

const IndexPage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage,
    ourStaffImage,
    pageResources
  }
}: Props) => {
  const currentFeeSchedule = pageResources.edges[0].node.pageDocuments[0]
  return (
    <div>
      <Helmet
        title={`Aged care services | ${siteMetadata.title}`}
        description="Our services can support you to maintain your freedom and independence at home."
        image={bannerImage}
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        themeColor={agedCareThemeFull.medium}
      />
      <Layout
        theme={agedCareThemeFull}
        fixedCTAPhoneNumber="1800792359"
        fixedCTAContact={PageForm}
      >
        <Banner
          title="Aged care services"
          subTitle="Our services can support you to maintain your freedom and
          independence at home."
          image={bannerImage}
          breadcrumbs={[routesObject.home, routesObject.service]}
        />
        <Container>
          <Row className="flex-wrap">
            <Box className="layout-readable">
              <Heading size={2}>Our services</Heading>
              <p>
                The Australian Government subsidises a range of aged care
                services in Australia through a variety of different programs.
                These include{" "}
                <Link
                  className="link color-lwb-black underline"
                  to="/services/aged-care/emergency-aged-care-services/"
                >
                  Emergency Aged Care Services
                </Link>
                ,{" "}
                <Link
                  className="link color-lwb-black underline"
                  to="/services/aged-care/commonwealth-home-support-program/"
                >
                  Commonwealth Home Support Programme (CHSP)
                </Link>
                ,{" "}
                <Link
                  className="link color-lwb-black underline"
                  to="/services/aged-care/home-care-packages/"
                >
                  Home Care Packages
                </Link>{" "}
                and{" "}
                <Link
                  className="link color-lwb-black underline"
                  to="/services/aged-care/veterans-home-care-program/"
                >
                  Veterans Home Care
                </Link>
                . We offer a variation of the below services using these
                packages as well as fee for service in locations all across
                Australia.
              </p>
            </Box>
            <IconGrid
              selectIcon={iconSelector}
              items={[
                {
                  icon: "groceries",
                  title: "Daily living and life tasks",
                  description:
                    "Encouraging you towards greater independence and confidence."
                },
                {
                  icon: "speechalt",
                  iconHeight: "35",
                  title: "Companionship, community and social support",
                  description:
                    "Supporting you to get out and about to do the things you enjoy."
                },
                {
                  icon: "shower",
                  title: "Personal care and mobile services",
                  description:
                    "Assisting with your personal comfort, security and wellbeing."
                },
                {
                  icon: "clinical",
                  title: "Therapeutic and clinical care",
                  description:
                    "Supporting and connecting you with allied health and nursing care."
                },
                {
                  icon: "cleaning",
                  title: "Cleaning and household tasks",
                  description:
                    "Helping you keep your home clean and comfortable."
                },
                {
                  icon: "maintenance",
                  title: "Home modifications, maintenance and handyman",
                  description:
                    "Improving accessibility around your home for your safety and independence."
                },
                {
                  icon: "garden",
                  title: "Garden maintenance and modifications",
                  description:
                    "Providing a helping hand around the garden for tasks big and small."
                },
                {
                  icon: "respitealt",
                  title: "Respite care",
                  description:
                    "Respite can give carers a break to do everyday activities and attend appointments, with peace of mind that their loved one is being cared for."
                }
              ]}
            />
          </Row>
        </Container>
        <PromoPanelRightImage image={ourStaffImage} color={greyXXXLight}>
          <div>
            <Heading size={2}>Our staff</Heading>
            <p>
              Our experienced staff provide dedicated, individual support, based
              on your needs. They will provide a home care plan developed with
              you, to ensure the services we provide are helping you meet your
              needs and goals.
            </p>
          </div>
        </PromoPanelRightImage>
        <Container>
          <Row className="relative">
            <Box className="w-100 w-60-l">
              <Heading size={2} topMargin="standard">
                Costs and eligibility
              </Heading>
              <p>
                If you are over 65 years, or over 50 years for Aboriginal and
                Torres Strait Islanders, you can register with{" "}
                <a
                  className="link color-lwb-black underline"
                  href="https://www.myagedcare.gov.au/"
                >
                  My Aged Care
                </a>{" "}
                to find out if you are eligible for home care services. The
                government will advise you if you are eligible for subsidies and
                whether you will need to contribute to the cost of your
                services. You can estimate your fees using the home care fee
                estimator on the{" "}
                <a
                  className="link color-lwb-black underline"
                  href="https://www.myagedcare.gov.au/"
                >
                  My Aged Care website
                </a>
                .
              </p>
              <p>
                Our services are also available to the whole community through
                fee for service. Whether its help with household tasks or
                maintaining your home we can help you find the service you are
                looking for.
              </p>
              <ReadMoreLink
                text="Download the fee schedule"
                href={currentFeeSchedule.file.url}
                className="mt3"
              />
            </Box>
            <Box className="w-100 w-34-l dn db-l absolute-l top-0-l right-0-l z-5">
              {PageForm}
            </Box>
          </Row>
        </Container>
        <ContactUsSection
          title="Getting Started"
          formId="mobile-aged-care-contact-form"
          formTitle="Contact us"
          contactMethod="phone"
          enquiryType="Aged Care"
        />
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row className="flex-wrap">
              <Box className="w-100 w-60-l">
                <Heading size={2}>Where we offer services</Heading>
                <p>
                  Please note the services available are different in each
                  location. Please contact us to find out more.
                </p>
              </Box>
              <Box className="w-100 mt4">
                <Panel background="bg-lwb-white" panelLine>
                  <Row className="flex-wrap">
                    <Box className="w-100 w-25-ns">
                      <LocationList
                        state="Australian Capital Territory"
                        locations={["Canberra"]}
                      />
                      <LocationList
                        state="New South Wales"
                        locations={[
                          "Albury",
                          "Bathurst",
                          "Broken Hill",
                          "Catalina",
                          "Grafton",
                          "Northern Rivers",
                          "Orange",
                          "Tamworth"
                        ]}
                      />
                      <LocationList
                        state="Northern Territory"
                        locations={["Darwin", "Katherine"]}
                      />
                    </Box>
                    <Box className="w-100 w-25-ns">
                      <LocationList
                        state="Queensland"
                        locations={[
                          "Brisbane",
                          "Bundaberg",
                          "Cairns",
                          "Gold Coast",
                          "Hervey Bay",
                          "Ipswich",
                          "Mackay",
                          "Moreton Bay",
                          "Rockhampton",
                          "Sunshine Coast",
                          "Toowoomba",
                          "Townsville"
                        ]}
                      />
                    </Box>
                    <Box className="w-100 w-25-ns">
                      <LocationList
                        state="South Australia"
                        locations={["Adelaide metro area"]}
                      />
                      <LocationList
                        state="Tasmania"
                        locations={["Rocherlea", "Claremont"]}
                        note="(Continuity of support program only)"
                      />
                    </Box>
                    <Box className="w-100 w-25-ns">
                      <LocationList
                        state="Victoria"
                        locations={[
                          "Melbourne",
                          "Metro",
                          "Barwon",
                          "South West Gippsland",
                          "Grampians",
                          "Hume",
                          "Loddon",
                          "Mallee",
                          "Phillip Island"
                        ]}
                      />
                      <LocationList
                        state="Western Australia"
                        locations={["Broome"]}
                      />
                    </Box>
                  </Row>
                </Panel>
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(relativePath: { regex: "/img-acs-index-header.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    ourStaffImage: file(relativePath: { regex: "/img-acs-index-staff.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200, quality: 90)
      }
    }
    pageResources: allContentfulPageResources(
      filter: {
        title: { eq: "home-care-packages" }
        pageDocuments: {
          elemMatch: {
            title: { eq: "Home-Care-Packages-Current-Fee-Schedule" }
          }
        }
      }
    ) {
      edges {
        node {
          pageDocuments {
            file {
              url
            }
            title
          }
        }
      }
    }
  }
`

export default IndexPage
